import { getStorageFileUploadEndpoint, StorageUploadFileRequest, StorageUploadFileResponse } from '@cp-shared-9/apis';
import { AxiosInstance } from 'axios';

type FileId = string;

export function uploadFilesToRequestedUrls(files: File[], CpDataApi: AxiosInstance): Promise<FileId[]> {
    const uploadFileToTemporalUrl = async (storageUpload: StorageUploadFileResponse, file: File): Promise<FileId> => {
        return await CpDataApi.put(storageUpload.temporalUrl, file).then(() => storageUpload.id);
    };

    return Promise.all(
        files.map(file => {
            const data: StorageUploadFileRequest = { name: file.name, mimeType: file.type };
            return CpDataApi.post<StorageUploadFileResponse>(getStorageFileUploadEndpoint(), data).then(
                async response => {
                    return await uploadFileToTemporalUrl(response.data, file);
                },
            );
        }),
    );
}
